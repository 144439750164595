import React from "react";

import { createLeadView, LeadViewComponent } from "../_lib/view";

import { useAuth } from "@api/auth";

import { isProductEnabled } from "@utils/products";

import { LeadStateProvider } from "../_lib/context";

import { LeadHeader } from "@leads/LeadHeader";
import { Grid, GridItem } from "@leads/Grid";

import { PersonalInformation } from "@leads/cards/PersonalInformation";
import { ContactInformation } from "@leads/cards/ContactInformation";
import { LeadInformation } from "@leads/cards/LeadInformation";
import { HomeInformation } from "@leads/cards/HomeInformation";
import { EmploymentInformation } from "@leads/cards/EmploymentInformation";
import { IdVerification } from "@leads/cards/IdVerification/Card";
import { MarketingInformation } from "@leads/cards/MarketingInformation";
import { CreditScore } from "@leads/cards/CreditScore/Card";
import { TradeVehicles } from "@leads/cards/TradeVehicle";
import { CreditInquiries } from "@leads/cards/CreditInquiries";
import { TradeLines } from "@leads/cards/TradeLines";

import Activity from "@leads/cards/Activity";
import Axis from "@leads/cards/Axis";
import DetailsForm from "@leads/cards/Details";

import { InfoCard } from "~/components/InfoCard";

import { Theme, makeStyles } from "@material-ui/core/styles";

import { ProductType } from "@api/graphql/types";

const useInfoCardStyles = makeStyles<Theme>((theme) => ({
	details: {
		height: "fit-content",
		marginBottom: "16px",
	},
	activity: {
		height: "fit-content",
		maxHeight: 512,
		overflowY: "auto",
	},
	innerGrid: {
		padding: 0,
	},
}));

const BasicView: LeadViewComponent = (props) => {
	return (
		<>
			<LeadHeader {...props} />
			<GridView {...props} />
		</>
	);
};

// eslint-disable-next-line react/display-name
export const GridView: LeadViewComponent = (props) => {
	const styles = useInfoCardStyles();
	const { selectedCompany } = useAuth();
	const isPrequalEnabled = isProductEnabled(
		selectedCompany,
		ProductType.LenderTool,
	);
	const isProduction = process.env.GATSBY_ACTIVE_ENV === "production";
	return (
		<Grid>
			<GridItem sm={12} md={9}>
				<Grid className={styles.innerGrid}>
					<GridItem sm={12} md={6}>
						<PersonalInformation />
					</GridItem>

					<GridItem sm={12} md={6}>
						<CreditScore />
					</GridItem>

					<GridItem sm={12}>
						<ContactInformation />
					</GridItem>

					{isPrequalEnabled && !isProduction && (
						<GridItem sm={12}>
							<Axis />
						</GridItem>
					)}

					<GridItem sm={isPrequalEnabled && !isProduction ? 12 : 6}>
						<LeadInformation />
					</GridItem>

					<GridItem sm={12}>
						<IdVerification />
					</GridItem>
					<GridItem>
						<CreditInquiries />
					</GridItem>
					<GridItem>
						<TradeLines />
					</GridItem>
					<GridItem>
						<HomeInformation />
					</GridItem>
					<GridItem>
						<EmploymentInformation />
					</GridItem>
					<TradeVehicles />
					<GridItem sm={12}>
						<MarketingInformation />
					</GridItem>
				</Grid>
			</GridItem>
			<GridItem sm={12} md={3}>
				<InfoCard title="Details" className={styles.details}>
					<DetailsForm {...props} />
				</InfoCard>
				<InfoCard title="Activity" className={styles.activity}>
					<Activity {...props} />
				</InfoCard>
			</GridItem>
		</Grid>
	);
};

const LeadView: LeadViewComponent = (props) => {
	const layoutProps = {
		...props,
		pageProps: props.params,
		title: "Lead Details",
		showBack: true,
		backTo: "/leads/",
		children: undefined,
	};

	return (
		<LeadStateProvider {...layoutProps}>
			<BasicView {...layoutProps} />
		</LeadStateProvider>
	);
};

export default createLeadView(LeadView, {});
