import React from "react";

import { useUserQuery } from "@api/users";

import { useLeadState } from "@leads/context";
import { LeadViewComponent } from "@leads/view";

import { LeadPriority, LeadState } from "@api/graphql/types";

import { customStyles } from "./styles";

import Skeleton from "@material-ui/lab/Skeleton";
import { DistributeLead } from "./DistributeLead";
import { Selector } from "./Selector";

const DetailsForm: LeadViewComponent = () => {
	const {
		state: { lead },
	} = useLeadState();

	const { groups } = useUserQuery();

	const styles = customStyles();

	const formatLeadStatus = (state: LeadState) => {
		switch (state) {
			case LeadState.New:
				return "New";
			case LeadState.AppointmentBooked:
				return "Appointment booked";
			case LeadState.Contacted:
				return "Contacted";
			case LeadState.Dead:
				return "Dead";
			case LeadState.Sold:
				return "Sold";
			case LeadState.Working:
				return "Working";
			default:
				return "New";
		}
	};

	const formatLeadPriority = (priority: LeadPriority) => {
		switch (priority) {
			case LeadPriority.Low:
				return "Low";
			case LeadPriority.Medium:
				return "Medium";
			case LeadPriority.High:
				return "High";
			default:
				return "Medium";
		}
	};

	if (!lead || !groups) {
		return (
			<div className={styles.loading}>
				<Skeleton variant="rect" width="50%" />
				<Skeleton variant="rect" width="50%" />
				<Skeleton variant="rect" width="50%" />
			</div>
		);
	}

	const usersGroup = groups?.[0]?.users || [];

	return (
		<div className={styles.root}>
			<DistributeLead />
			<Selector
				field="assignee"
				label="Assignee"
				options={usersGroup?.map((user) => ({
					label: user.email,
					value: user.email,
				}))}
			/>
			<Selector
				field="state"
				label="Status"
				options={Object.values(LeadState).map((status) => ({
					label: formatLeadStatus(status),
					value: status,
				}))}
			/>
			<Selector
				field="priority"
				label="Priority"
				options={Object.values(LeadPriority).map((priority) => ({
					label: formatLeadPriority(priority),
					value: priority,
				}))}
			/>
		</div>
	);
};

export default DetailsForm;
