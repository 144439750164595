import React, { useCallback, useState } from "react";

import { useLeadActions } from "@api/leads";

import { useFeedbackContext } from "~/components/Feedback";
import { Typography } from "~/components/Typography";

import { Box, MenuItem, Select } from "@material-ui/core";

import { useLeadState } from "@leads/context";

import { Company } from "@api/graphql/types";

import { customStyles } from "./styles";
import { AppDialog } from "~/components/Modal";
import { PrimaryButton, SecondaryButton } from "~/components/Button";

interface IModalProps {
	company: Company | undefined;
	confirm: () => void;
	cancel: () => void;
}

const Modal: React.FC<IModalProps> = ({ company, confirm, cancel }) => {
	const styles = customStyles();
	return (
		<AppDialog
			open={!!company}
			title="Distribute Lead"
			maxWidth="xs"
			onClose={cancel}
		>
			<Box
				position="relative"
				display="flex"
				flexDirection="column"
				justifyContent="center"
				marginTop={2}
			>
				<Typography>
					Are you sure you'd like to distribute this lead to <span className={styles.bold}>{company?.name}</span>?<br /><br />
					This action cannot be reversed.
				</Typography>
				<Box
					position="relative"
					display="flex"
					flexDirection="row"
					justifyContent="center"
					marginTop={2}
					gridGap={10}
				>
					<PrimaryButton onClick={confirm}>
						Confirm
					</PrimaryButton>

					<SecondaryButton onClick={cancel}>
						Cancel
					</SecondaryButton>
				</Box>
			</Box>
		</AppDialog>
	);
};

export const DistributeLead = () => {
	const {
		state: { lead },
	} = useLeadState();

	const { distributeLead } = useLeadActions(lead.id);
	const { handleOpenFeedback } = useFeedbackContext();
	const [distributeTo, setDistributeTo] = useState<Company | undefined>(undefined);

	const styles = customStyles();

	const companies = lead?.company?.distributorRelationshipsByDistributorCompanyId.nodes.map(c => c.company as Company).filter(Boolean) || [];
	const options: Company[] = [lead.company as Company].concat(companies);
	if (!lead.company) {
		return null;
	}

	const handleChange = async (
		event: React.ChangeEvent<{ value: unknown }>,
	) => {
		const option = options.find(op => op.id === event.target.value as number)
		setDistributeTo(option);
	};

	const confirm = async () => {
		if (!lead.company) {
			handleOpenFeedback({
				message: "No Company Selected!",
				severity: "error",
			});
			return;
		}

		try {
			await distributeLead(distributeTo?.id!);
			setDistributeTo(undefined);
			handleOpenFeedback({
				message: "Distributed Lead Successfully!",
				severity: "success",
			});
		} catch (error) {
			handleOpenFeedback({
				message: "Something went wrong. Try again or contact support.",
				severity: "error",
			});
		}

	};

	const cancel = () => {
		setDistributeTo(undefined);
	};

	return (
		<div className={styles.selectOption}>
			<Typography variant="caption" className={styles.selectLabel}>
				Company:
			</Typography>
			<Select
				id="select-lead-asignee"
				className={styles.select}
				value={lead.company.id}
				onChange={handleChange}
				MenuProps={{
					getContentAnchorEl: () => null!,
				}}
				disabled={!companies.length}
			>
				{options.map(({ id, name }) => {
					return (
						<MenuItem key={`user-status-${id}`} value={id}>
							{name}
						</MenuItem>
					);
				})}
			</Select>

			<Modal company={distributeTo} confirm={confirm} cancel={cancel} />
		</div>
	);
};
