import { makeStyles } from "@material-ui/core";

export const customStyles = makeStyles(() => ({
	root: {
		display: "flex",
		flexDirection: "column",
		gap: 8,
		padding: 24,
		backgroundColor: "#ffffff",
		boxShadow: "none",
		width: "100%",
	},
	select: {
		width: "66%",
		maxWidth: 120,
		marginTop: 8,
	},
	selectOption: {
		display: "flex",
		gap: 8,
	},
	selectLabel: {
		alignSelf: "end",
		width: "33%",
	},
	loading: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: 32,
		padding: 24,
		flexDirection: "column",
		height: "100%",
	},
	bold: {
		fontWeight: "bold",
	}
}));
